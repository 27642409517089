"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const constants = require("@/common/constants");
const vueRoot_1 = require("@/client/components/vueRoot");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const SoundManager_1 = require("@/client/utils/SoundManager");
const Phase_1 = require("@/common/Phase");
const paths_1 = require("@/common/app/paths");
const statusCode_1 = require("@/common/http/statusCode");
const Types_1 = require("@/common/Types");
const AppErrorId_1 = require("@/common/app/AppErrorId");
let ui_update_timeout_id;
let documentTitleTimer;
exports.default = vue_1.default.extend({
    name: 'waiting-for',
    props: {
        playerView: {
            type: Object,
        },
        players: {
            type: Array,
        },
        settings: {
            type: Object,
        },
        waitingfor: {
            type: Object,
        },
    },
    data() {
        return {
            waitingForTimeout: this.settings.waitingForTimeout,
            autopass: this.playerView.autopass,
        };
    },
    methods: {
        animateTitle() {
            const sequence = '\u25D1\u25D2\u25D0\u25D3';
            const first = document.title[0];
            const position = sequence.indexOf(first);
            let next = sequence[0];
            if (position !== -1 && position < sequence.length - 1) {
                next = sequence[position + 1];
            }
            document.title = next + ' ' + this.$t(constants.APP_NAME);
        },
        onsave(out) {
            const xhr = new XMLHttpRequest();
            const root = (0, vueRoot_1.vueRoot)(this);
            const showAlert = root.showAlert;
            if (root.isServerSideRequestInProgress) {
                console.warn('Server request in progress');
                return;
            }
            root.isServerSideRequestInProgress = true;
            xhr.open('POST', paths_1.paths.PLAYER_INPUT + '?id=' + this.playerView.id);
            xhr.responseType = 'json';
            xhr.onload = () => {
                if (xhr.status === statusCode_1.statusCode.ok) {
                    root.screen = 'empty';
                    root.playerView = xhr.response;
                    root.playerkey++;
                    root.screen = 'player-home';
                    if (this.playerView.game.phase === 'end' && window.location.pathname !== paths_1.paths.THE_END) {
                        (window).location = (window).location;
                    }
                }
                else if (xhr.status === statusCode_1.statusCode.badRequest && xhr.responseType === 'json') {
                    if (xhr.response.id === AppErrorId_1.INVALID_RUN_ID) {
                        showAlert(xhr.response.message, () => {
                            setTimeout(() => window.location.reload(), 100);
                        });
                    }
                    else {
                        showAlert(xhr.response.message);
                    }
                }
                else {
                    showAlert('Unexpected response from server. Please try again.');
                }
                root.isServerSideRequestInProgress = false;
            };
            xhr.send(JSON.stringify({ runId: this.playerView.runId, ...out }));
            xhr.onerror = function () {
                root.isServerSideRequestInProgress = false;
            };
        },
        reset() {
            const xhr = new XMLHttpRequest();
            const root = (0, vueRoot_1.vueRoot)(this);
            if (root.isServerSideRequestInProgress) {
                console.warn('Server request in progress');
                return;
            }
            root.isServerSideRequestInProgress = true;
            xhr.open('GET', paths_1.paths.RESET + '?id=' + this.playerView.id);
            xhr.responseType = 'json';
            xhr.onload = () => {
                this.loadPlayerViewResponse(xhr);
            };
            xhr.send();
            xhr.onerror = function () {
                root.isServerSideRequestInProgress = false;
            };
        },
        updateAutopass() {
            const xhr = new XMLHttpRequest();
            const root = (0, vueRoot_1.vueRoot)(this);
            if (root.isServerSideRequestInProgress) {
                console.warn('Server request in progress');
                return;
            }
            xhr.onload = () => {
                root.isServerSideRequestInProgress = true;
            };
            xhr.open('GET', paths_1.paths.AUTOPASS + '?id=' + this.playerView.id + '&autopass=' + this.autopass);
            xhr.responseType = 'json';
            xhr.send();
            xhr.onerror = function () {
                root.isServerSideRequestInProgress = false;
            };
        },
        loadPlayerViewResponse(xhr) {
            const root = (0, vueRoot_1.vueRoot)(this);
            const showAlert = (0, vueRoot_1.vueRoot)(this).showAlert;
            if (xhr.status === 200) {
                root.screen = 'empty';
                root.playerView = xhr.response;
                root.playerkey++;
                root.screen = 'player-home';
                if (this.playerView.game.phase === 'end' && window.location.pathname !== paths_1.paths.THE_END) {
                    (window).location = (window).location;
                }
            }
            else if (xhr.status === statusCode_1.statusCode.badRequest && xhr.responseType === 'json') {
                showAlert(xhr.response.message);
            }
            else {
                showAlert('Unexpected response from server. Please try again.');
            }
            root.isServerSideRequestInProgress = false;
        },
        waitForUpdate() {
            const vueApp = this;
            const root = (0, vueRoot_1.vueRoot)(this);
            clearTimeout(ui_update_timeout_id);
            const askForUpdate = () => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', paths_1.paths.API_WAITING_FOR + window.location.search + '&gameAge=' + this.playerView.game.gameAge + '&undoCount=' + this.playerView.game.undoCount);
                xhr.onerror = function () {
                    root.showAlert('Unable to reach the server. The server may be restarting or down for maintenance.', () => vueApp.waitForUpdate());
                };
                xhr.onload = () => {
                    if (xhr.status === statusCode_1.statusCode.ok) {
                        const result = xhr.response;
                        if (result.result === 'GO') {
                            root.updatePlayer();
                            if (Notification.permission !== 'granted') {
                                Notification.requestPermission();
                            }
                            else if (Notification.permission === 'granted') {
                                const notificationOptions = {
                                    icon: 'favicon.ico',
                                    body: 'It\'s your turn!',
                                };
                                const notificationTitle = constants.APP_NAME;
                                try {
                                    new Notification(notificationTitle, notificationOptions);
                                }
                                catch (e) {
                                    if (!window.isSecureContext || !navigator.serviceWorker) {
                                        return;
                                    }
                                    navigator.serviceWorker.ready.then((registration) => {
                                        registration.showNotification(notificationTitle, notificationOptions);
                                    }).catch((err) => {
                                        console.warn('Failed to display notification with serviceWorker', err);
                                    });
                                }
                            }
                            const soundsEnabled = (0, PreferencesManager_1.getPreferences)().enable_sounds;
                            if (soundsEnabled)
                                SoundManager_1.SoundManager.playActivePlayerSound();
                            return;
                        }
                        else if (result.result === 'REFRESH') {
                            if ((0, Types_1.isPlayerId)(this.playerView.id)) {
                                root.updatePlayer();
                            }
                            else {
                                root.updateSpectator();
                            }
                            return;
                        }
                        vueApp.waitForUpdate();
                    }
                    else {
                        root.showAlert(`Received unexpected response from server (${xhr.status}). This is often due to the server restarting.`, () => vueApp.waitForUpdate());
                    }
                };
                xhr.responseType = 'json';
                xhr.send();
            };
            ui_update_timeout_id = window.setTimeout(askForUpdate, this.waitingForTimeout);
        },
    },
    mounted() {
        document.title = this.$t(constants.APP_NAME);
        window.clearInterval(documentTitleTimer);
        if (this.waitingfor === undefined) {
            this.waitForUpdate();
        }
        if (this.playerView.players.length > 1 && this.waitingfor !== undefined) {
            documentTitleTimer = window.setInterval(() => this.animateTitle(), 1000);
        }
    },
    computed: {
        Phase() {
            return Phase_1.Phase;
        },
    },
});
